<template>
  <pigmentation-template
    :has-hyper-pigmentation="hasHyperPigmentation"
    :hyperpigmentation="hyperpigmentation"
    :hyperpigmentation-ai-selected="hyperpigmentationAiSelected"
    :spot-shapes="spotShapes"
    :spot-shapes-ai-selected="spotShapesAiSelected"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import PigmentationTemplate from '@/modules/questionnaire/components/steps/photo-analysis/skin-pigmentation/pigmentation/PigmentationTemplate';

import { makeStep, mapAiSelectedFields, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { no, yes } from '@/modules/questionnaire/api/helpers';

const { requiredField } = fieldBuilder;

const FIELD_NAMES_TO_RESET = ['spotShapes'];

const AI_SELECTED_FIELD_NAMES = ['hyperpigmentation', ...FIELD_NAMES_TO_RESET];

const FIELDS = [...AI_SELECTED_FIELD_NAMES.map(requiredField)];

export default {
  name: 'Pigmentation',
  components: { PigmentationTemplate },
  mixins: [makeStep(FIELDS), mapAiSelectedFields(AI_SELECTED_FIELD_NAMES), resetStoreValues],
  computed: {
    hasHyperPigmentation() {
      return yes(this.hyperpigmentation);
    }
  },
  watch: {
    hyperpigmentation(newValue) {
      if (no(newValue)) {
        this.resetStoreFieldsByName(FIELD_NAMES_TO_RESET);
        this.showNextStep();

        return;
      }

      this.scrollTo('#spot-shapes');
    },
    spotShapes() {
      this.showNextStep();
    }
  },
  methods: {
    isFieldVisible(fieldName) {
      if (fieldName === 'spotShapes') {
        return this.hasHyperPigmentation;
      }
    }
  }
};
</script>
